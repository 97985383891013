import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Scope } from '../models/auth/Scope';
import { Product, ProductDetails } from '../models/products/Product';
import { ProductToOrder } from '../models/orders/orders';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(public http: HttpClient) { }

  cart: ProductToOrder[] = [];

  getScopesForToken() {
    return this.http.get<{ result: Scope[] }>(`${environment.baseUrl}oauth/scopes`);
  }

  getProducts() {
    return this.http.get<{ result: Product[] }>(`${environment.baseUrl}store/products`);
  }

  getProductById(id: number) {
    return this.http.get<{ result: ProductDetails }>(`${environment.baseUrl}store/getProduct?productId=${id}`);
  }

  setCartItem(product: ProductToOrder) {
    if (!this.cart.includes(product)) {
      this.cart.push(product);
    }
  }

  removeCartItem(product: ProductToOrder) {
    const index = this.cart.indexOf(product, 0);
    if (index > -1) {
      this.cart.splice(index, 1);
    }
  }

  getCart() {
    return this.cart;
  }

}
