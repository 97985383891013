import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {
  SignupForm!:FormGroup;

  ngOnInit(){
    this.SignupForm = new FormGroup({
      'userData': new FormGroup({
          'fullName':new FormControl(null),
          'email':new FormControl(null),
          'address':new FormControl(null),
          'phone':new FormControl(null),
          'country':new FormControl(null),
          'CAP':new FormControl(null),
          'city':new FormControl(null),
      }),

    });
  }

  onSubmit(){
    console.log(this.SignupForm);
  }

}
