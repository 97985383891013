import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appStickyNav]'
})
export class StickyNavDirective {
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const nav = this.elementRef.nativeElement;

    if (window.scrollY > 100) { // Adjust this value as needed
      this.renderer.setStyle(nav, 'position', 'fixed');
      this.renderer.setStyle(nav, 'top', '0');
    } else {
      this.renderer.removeStyle(nav, 'position');
      this.renderer.removeStyle(nav, 'top');
    }
  }
}
