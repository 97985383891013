<app-navbar></app-navbar>
<div class="content" *ngIf="(productDetails$ | async) as product">
  <div class="product">
    <img *ngIf="product.result.sync_product.name==='Mohamed Ali tee'" src="assets/images/ali.png" alt="Mohamed Ali tee">
    <img *ngIf="product.result.sync_product.name==='American psyco tee'" src="assets/images/psyco.png"
      alt="American psyco tee">
    <img *ngIf="product.result.sync_product.name==='Andrew Tate tee'" src="assets/images/tate.png"
      alt="Andrew Tate tee">
  </div>
  <div class="decription">
    <h2>{{product.result.sync_product.name}}</h2>

    <h4>15,00 €</h4>

    <ul>
      <li>Composition: 100% cotton</li>
      <li>Shipping throughout Europe</li>
    </ul>

    <h5>Size:</h5>
    <mat-chip-list aria-label="sizes">
      <mat-chip (click)="setSize('S')" [selected]="this.size==='S'">
        S
      </mat-chip>
      <mat-chip (click)="setSize('M')" [selected]="this.size==='M'">
        M
      </mat-chip>
      <mat-chip (click)="setSize('L')" [selected]="this.size==='L'">
        L
      </mat-chip>
      <mat-chip (click)="setSize('XL')" [selected]="this.size==='XL'">
        XL
      </mat-chip>
    </mat-chip-list>

    <mat-error *ngIf="showSizeError" class="sizeError">Please select the size</mat-error>

    <button mat-raised-button class="addToChartButton" (click)="addToCart(product.result.sync_product)">Add to
      chart</button>

  </div>
</div>
<app-footer></app-footer>