import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  cartItemsCount: number = 0;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    this.cartItemsCount = this.productService.getCart().length;
  }
}
