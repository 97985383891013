<app-navbar></app-navbar>
<div class="content">
    <div *ngIf="!cart.length" style="text-align: center;">
        <mat-icon>remove_shopping_cart</mat-icon>
        <p>Your cart is empty</p>
        <button mat-raised-button class="navigateHomeButton" (click)="navigateToHome()">Continue shopping</button>
    </div>
    <div *ngIf="cart.length" class="cartHeader">
        <span style="width: 200px"></span>
        <span style="width: 150px">Product</span>
        <span style="width: 150px">Price</span>
        <span style="width: 150px">Size</span>
        <span style="width: 20px"></span>
    </div>
    <div *ngFor="let cartItem of cart">
        <app-cart-item [product]="cartItem"></app-cart-item>
    </div>

    <button *ngIf="cart.length" mat-raised-button class="navigateHomeButton" (click)="checkout()">Checkout
        <mat-icon>shopping_basket</mat-icon></button>
</div>
<app-footer></app-footer>