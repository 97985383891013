import { Component, Input } from '@angular/core';
import { ProductToOrder } from 'src/app/models/orders/orders';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent {
  @Input() product!: ProductToOrder;

  constructor(private productService: ProductService) { }

  removeItem() {
    this.productService.removeCartItem(this.product)
  }

}
