import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/products/Product';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  products$: Observable<{result: Product[]}> = new Observable();

  constructor(public productService: ProductService) { }

  ngOnInit(): void {
/*     this.productService.getScopesForToken().subscribe(
      v => console.log(v)
    ) */

    this.products$ = this.productService.getProducts();
  }


}
