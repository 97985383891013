<app-header></app-header>
<div class="cards-container" *ngIf="(products$ | async) as products">
  <div class="card" *ngIf="products.result[0].name === 'Mohamed Ali tee'"
    [routerLink]="['/product', products.result[0].id]">
    <img src="assets/images/ali.png" alt="Mohamed Ali tee">
    <div class="price">€ 15 EUR <fa-icon [icon]="['fas','cart-arrow-down']"></fa-icon> <i class="fas fa-cart-arrow-down"
        style="font-size:24px"></i></div>
  </div>
  <div class="card" *ngIf="products.result[1].name === 'American psyco tee'"
    [routerLink]="['/product', products.result[1].id]">
    <img src="assets/images/psyco.png" alt="American Psyco tee">
    <div class="price">€ 15 EUR <fa-icon [icon]="['fas','cart-arrow-down']"></fa-icon> <i class="fas fa-cart-arrow-down"
        style="font-size:24px"></i></div>
  </div>
  <div class="card" *ngIf="products.result[2].name === 'Andrew Tate tee'"
    [routerLink]="['/product', products.result[2].id]">
    <img src="assets/images/tate.png" alt="Andrew Tate tee">
    <div class="price">€ 15 EUR <fa-icon [icon]="['fas','cart-arrow-down']"></fa-icon> <i class="fas fa-cart-arrow-down"
        style="font-size:24px"></i></div>
  </div>
</div>
<app-footer></app-footer>