import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductToOrder } from 'src/app/models/orders/orders';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  constructor(private productService: ProductService, private router: Router) { }

  cart: ProductToOrder[] = [];

  ngOnInit(): void {
    this.cart = this.productService.getCart();
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  checkout() {
    this.router.navigate(['/checkout']);
  }

}
