<app-navbar></app-navbar>
<div class="content">
  <div class="row">
    <div>
      <form [formGroup]="SignupForm" (ngSubmit)="onSubmit()">
        <div formGroupName="userData">
          <div class="form-group">
            <label for="fullName">Full name</label>
            <input type="text" class="form-control" id="fullName" formControlName="fullName">
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" formControlName="email">
          </div>
          <div class="form-group">
            <label for="address">address</label>
            <input type="address" class="form-control" id="address" formControlName="address">
          </div>
          <div class="form-group">
            <label for="phone">phone</label>
            <input type="phone" class="form-control" id="phone" formControlName="phone">
          </div>
          <div class="form-group">
            <label for="country">country</label>
            <input type="country" class="form-control" id="country" formControlName="country">
          </div>
          <div class="form-group">
            <label for="CAP">CAP</label>
            <input type="CAP" class="form-control" id="CAP" formControlName="CAP">
          </div>
          <div class="form-group">
            <label for="city">city</label>
            <input type="city" class="form-control" id="city" formControlName="city">
          </div>
        </div>

        <button class="btn btn-primary" type="submit">Submit</button>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>