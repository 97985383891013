import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductToOrder } from 'src/app/models/orders/orders';
import { Product, ProductDetails } from 'src/app/models/products/Product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor(private productService: ProductService, private ativatedroute: ActivatedRoute, private router: Router) { }

  productDetails$: Observable<{ result: ProductDetails }> = new Observable();

  size: string = '';
  productToOrder: ProductToOrder = {
    id: 0,
    name: '',
    size: '',
    imgSrc: ''
  }
  showSizeError: boolean = false;


  ngOnInit(): void {
    const idParam = this.ativatedroute.snapshot.paramMap.get("id");
    const productId: number | null = idParam ? +idParam : null;

    if (productId !== null) {
      this.productDetails$ = this.productService.getProductById(productId);
    }
    else {
      //todo: lanciare eccezione
    }
  }

  setSize(size: string) {
    this.size = size;
    this.showSizeError = false;
  }

  addToCart(product: Product) {
    if (this.size === '') {
      this.showSizeError = true;
    } else {
      this.productToOrder.id = product.id;
      this.productToOrder.name = product.name;
      this.productToOrder.size = this.size;
      this.productToOrder.imgSrc = product.thumbnail_url;
      this.productService.setCartItem(this.productToOrder);
      this.router.navigate(['/cart']);
    }
  }

}

